import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import MainPage2019 from './pages/MainPage2019/MainPage2019';
import MainPage2020 from './pages/MainPage2020/MainPage2020';
import MainPage2021 from './pages/MainPage2021/MainPage2021';
import MainPage2022 from './pages/MainPage2022/MainPage2022';
import MainPage2023 from './pages/MainPage2023/MainPage2023';
import MainPage2024 from './pages/MainPage2024/MainPage2024';
import Promo2019 from './pages/Promo2019/Promo2019';

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/promo">
        <Promo2019 />
      </Route>

      <Route path="/2019">
        <MainPage2019 />
      </Route>

      <Route path="/2020">
        <MainPage2020 />
      </Route>

      <Route path="/2021">
        <MainPage2021 />
      </Route>

      <Route path="/2022">
        <MainPage2022 />
      </Route>

      <Route path="/2023">
        <MainPage2023 />
      </Route>

      <Route path="/2024">
        <MainPage2024 />
      </Route>

      <Route path="/">
        <MainPage2024 />
      </Route>
    </Switch>
  </Router>,
  document.getElementById('root')
);
