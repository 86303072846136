import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { makeTheme } from '../theme';
import '../style.css';

const theme = makeTheme('#53646b');

function MainPage() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="content-image-container">
          <video
            controls
            className="content-item video-player"
            controlsList="nodownload"
            disablePictureInPicture
            poster={`${process.env.PUBLIC_URL}/images/2024/cover.jpg`}
          >
            <source src={`https://okhumane.sfo2.cdn.digitaloceanspaces.com/2024-yule-log.mp4`} />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>

        <div className="content-image-container">
          <img className="content-item" alt="about yule log" src={`${process.env.PUBLIC_URL}/images/2024/header.webp`} />
        </div>

        <div className="content-image-container">
          <a href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=okhumane&id=126">
            <img className="content-item" alt="donate" src={`${process.env.PUBLIC_URL}/images/2024/donate.webp`} />
          </a>
        </div>

        <div className="content-image-container">
          <a href="https://youtu.be/oBdrrbUYmck" target="_blank" rel="noopener noreferrer" >
            <img className="content-item" alt="Stream on YouTube" src={`${process.env.PUBLIC_URL}/images/2024/youtube.webp`} />
          </a>
        </div>

        <div className="content-item content-pad" style={{display: "flex" }}>
          <img style={{ width: '50%', height: 'auto' }} alt="yule dog" src={`${process.env.PUBLIC_URL}/images/2024/puppy-point.jpg`} />
          <img style={{ width: '50%', height: 'auto' }} alt="yule cat" src={`${process.env.PUBLIC_URL}/images/2024/cat-curve.jpg`} />
        </div>

        <div className="content-image-container">
          <img className="content-item" alt="nice list" src={`${process.env.PUBLIC_URL}/images/2024/nice-list.webp`} />
        </div>

        <div className="content-image-container">
          <a href="https://www.okcballet.org/performances/">
            <img className="content-item content-pad" alt="ballet ad" src={`${process.env.PUBLIC_URL}/images/2024/ballet-ad.webp`} />
          </a>
        </div>

        <div className="content-image-container">
          <img className="content-item" alt="credits" src={`${process.env.PUBLIC_URL}/images/2024/credits.webp`} />
        </div>

        <div className="content-image-container">
          <img className="content-item" alt="about" src={`${process.env.PUBLIC_URL}/images/2024/about.webp`} />
        </div>

        <div className="content-image-container" style={{ marginBottom: '30px' }}>
          <a href="/2023">2023 Yule Log</a>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default MainPage;
